import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/core/locales/pt-br';

var railsEnv = $('body').data('env-url');
var profile = $('body').data('env-profile');

var URL = railsEnv;
var API = railsEnv +"/customers";

$(document).ready(function(){
    if ( window.location.href.split('/').includes('customers') && !window.location.href.split('/').includes('customers') ) {
    getPosts();
  };
});

function getPosts() {
  $.ajax({
        type: "POST",
        url: API + "/customers/customers/get_my_localities",
        dataType: "json",
        statusCode: {
          401: function(response){
          }
        },
        success: function (response) {
          if(response.success){
            renderPosts(response)
          }else{
            renderPosts(response)
          }
        }
      });
}

function renderPosts(posts) {
  $.ajax({
    type: "POST",
    url: API + "/customers/customers/get_my_attendances",
    dataType: "json",
    statusCode: {
      401: function(response){
      }
    },
    success: function (response) {

      if(response.success){
        renderPost(posts,response.data);
      }else{
        renderPost(posts,response.data);
      }
    }
  });
}

function renderPost(posts,data) {
  var calendarEl = document.getElementById('calendarCustomer');
  var Localities = JSON.parse(JSON.stringify(posts).split('"locality":').join('"title":'));
      //var data = JSON.parse(JSON.stringify(data).split('"speciality_id":').join('"id":').split('"start_attendance":').join('"start":').split('"end_attendance":').join('"end":').split('"teste":').join('"aaa":'))
      
      var calendar = new Calendar(calendarEl, {
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        defaultView: 'resourceTimeGridDay',
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin,resourceTimeGridPlugin ],
        timeZone: 'local',
        themeSystem: 'bootstrap',
        selectable: true,
        eventLimit: true,
        displayEventTime: false,
        height: '90%',
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        buttonIcons:{
          prev: 'left-single-arrow',
          next: 'right-single-arrow',
          prevYear: 'left-double-arrow',
          nextYear: 'right-double-arrow'
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          list: "Lista Semanal"
        },
        eventClick: function(info) {
          let dataStart = new Date(info.event.start);
          let dataFormatada = ((dataStart.getDate() )) + "/" + ((dataStart.getMonth() + 1)) + "/" + dataStart.getFullYear() + " Horario: " + dataStart.getHours() + ":" + dataStart.getMinutes(); 

          let dataEnd = new Date(info.event.end);
          let dataFormatadaEnd = ((dataEnd.getDate() )) + "/" + ((dataEnd.getMonth() + 1)) + "/" + dataEnd.getFullYear() + " Horario: " + dataEnd.getHours() + ":" + dataEnd.getMinutes(); 

          $('#visualizar #physiotherapist').text(info.event._def.extendedProps.physiotherapist);
          $('#visualizar #customer').text(info.event._def.extendedProps.customer);
          $('#visualizar #title').text(info.event.title);
          $('#visualizar #sala').text(info.event._def.extendedProps.sala);
          $('#visualizar #start').text(dataFormatada);
          $('#visualizar #end').text(dataFormatadaEnd);
          $('#visualizar #description').text(info.event._def.extendedProps.name_locality);
          $('#visualizar #card_color').text(info.event._def.extendedProps.card_color);

          if (info.event._def.extendedProps.card_color == "green"){
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('bg-success')
            document.querySelector('#card_header').classList.add('text-white')
          } else if (info.event._def.extendedProps.card_color == "blue"){
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('bg-primary')
            document.querySelector('#card_header').classList.add('text-white')
          }else if (info.event._def.extendedProps.card_color == "red"){
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('bg-danger')
            document.querySelector('#card_header').classList.add('text-white')
          }




          
          $('#visualizar').modal('show');
        },
        resources:Localities,
        events: data,
      });
      calendar.setOption('locale', 'pt-br');
      calendar.render();
    }
    




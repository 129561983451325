


$(document).ready(function(){
  $nameFieldSearch = $('#query_name') ;

  if($nameFieldSearch.lenght !== 0){
    $nameFieldSearch.keyup(function(e) {
      $('#query_name').val($nameFieldSearch.val().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    });
  }


})  



import datepickerFactory from 'jquery-datepicker';
import datetimepickerFactory from 'jquery-datetimepicker';
 
// Just pass your jquery instance and you're done
datepickerFactory($);


//$("#attendance_treatment_schedule").datepicker()



$(document).ready(function(){
  $category                         = $('#receivement_receivement_category');
  $type            									= $('#receivement_receivement_type');
  //DIVS
  $customersdivgroup           			= $('#customer_fields_group');
  $physiotherapistdivgroup	   			= $('#phisiotherapist_fields_group');
  //
  $paymentform											= $('#receivement_payment_method');
  $installments_div_row							= $('#receivement_installments_row_div');
  $brands														= $('#credit_card_brand_id_div');

  $physiotherapistid                = $('#receivement_physiotherapist_id');
  $customerid                       = $('select#receivement_customer_id');

  $customerid_field                 = $('#customer_id_field');
  $receivement_customer_id          = $("#custId");

	$type.change(function(){

    if ($type.val()!='3'){
      $customerid_field.css("display","flex");
      console.log($receivement_customer_id) 
      if ($receivement_customer_id.val()=="") {
        $customerid_field.change(function(){
          var valor = $('#receivement_customer_id').val();
          $.ajax({
            type: 'GET',
            url: 'customer_package_procedures',
            beforeSend: function(){
              
            },
            data: {valor: valor},
            success: function(msg){
              $("#packet_procedure").html(msg);
            }
          })
        });
      }else{
          var valor = $('#custId').val();
          console.log(valor)
          $.ajax({
            type: 'GET',
            url: 'customer_package_procedures',
            beforeSend: function(){
              
            },
            data: {valor: valor},
            success: function(msg){
              $("#packet_procedure").html(msg);
            }
          })
      }
    }  

    if ($type.val()=='3'){
      $customerid.val($("#select option:first").val());
      $('select#receivement_receivement_service').val($("#select option:first").val());
      $customersdivgroup.css("display","none");
      $customerid_field.css("display","none");
    	$physiotherapistdivgroup.css("display","flex");
     
    }else{
      $('select#receivement_receivement_service').val($("#select option:first").val());
    	$physiotherapistdivgroup.css("display","none");
    	$customersdivgroup.css("display","flex");
  	  $physiotherapistid.val("");
    }
  });

	$paymentform.change(function(){
    if ($paymentform.val()=="2" || $paymentform.val()=="4" || $paymentform.val()=="5"){
    	$brands.css("display","flex");
      $('select#receivement_receivement_service').val($("#select option:first").val());
    	$installments_div_row.css("display","flex");
      $installments_div_row.val($("#select option:first").val());
      
    }else if($paymentform.val()=="1"){
			$brands.css("display","flex");
      $('select#receivement_receivement_service').val($("#select option:first").val());
			$installments_div_row.css("display","none");
      $installments_div_row.val($("#select option:first").val());
      
    }else if($paymentform.val()=="0" || $paymentform.val()=="3" ){
    	$brands.css("display","none");
    	$installments_div_row.css("display","none");
 	    $installments_div_row.val($("#select option:first").val());
    }
  });

  $("#receivement_receivement_type").change(function(){
    if ( $("#receivement_receivement_type").val() == 0 ){
      $("#packet_procedure").css({ "display": "flex" });
      $("#customer_fields_group").css({ "display": "none" });
      
    }else{
      $("#packet_procedure").css({ "display": "none" });
      $("#customer_fields_group").css({ "display": "flex" });
    }
  });


})
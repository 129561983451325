// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { start as ujsStart } from '@rails/ujs';
import { start as turubolinkStart} from 'turbolinks';
import { start as activeStorageStart} from '@rails/activestorage';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import channels from 'channels';
import jQuery from 'jquery';
import 'bootstrap';
import '../stylesheets/application';
import '@fortawesome/fontawesome-free/js/all';
import jQuery_nested_form from 'jquery_nested_form';

import '@coreui/coreui';
//import '@coreui/icons';
import '@coreui/chartjs';
import '@coreui/utils';
import { Calendar } from '@fullcalendar/core';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';

import toastr from 'toastr'

window.toastr = toastr
require("chart.js")

require("trix")
require("@rails/actiontext")

import './healthInsurance/health_insurance';
import './attendance/validation';
import './calendar/calendar';
import './calendar/calendarAdmin';
import './calendar/calendarCustomer';
import './datepicker/datepicker';
import './cep/cep_manager';
import './cep/cep_manager_physiotherapist';
import './admin/customer';
import './admin/physiotherapist';
import './admin/enterprise';
import './admin/cep_manager';
import './admin/sale';
import './admin/payments';
import './admin/receivements';
import './utils/shared_functions';
import './physiotherapist/customer_forms';
//import './admin/accounts/revenue/chart_revenue_by_category'      TRATR A CHAMADA DAS FUNÇÕES 
//import './admin/accounts/revenue/chart_revenue_by_detail'      TRATR A CHAMADA DAS FUNÇÕES 
//import './admin/accounts/expenses/chart_expenses_by_detail'      TRATR A CHAMADA DAS FUNÇÕES 
//import './admin/accounts/expenses/chart_expenses_by_category'      TRATR A CHAMADA DAS FUNÇÕES 

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
// 
// 

$(document).ready(function(){
  $('[data-toggle="tooltip"]').tooltip(); 
});

//ujsStart();
//turubolinkStart();
activeStorageStart();
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")
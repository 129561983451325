$(document).ready(function(){
  $type            									= $('#sale_type_item_sale');
  $paymentform											= $('#sale_type_payment');
  $installments_div_row							= $('#sale_installments_row_div');
  $brands														= $('#credit_card_brand_id_div');

  $customerid                       = $('select#sale_customer_id');


	$type.change(function(){
    if ($type.val()=='0'){
      $customerid.val($("#select option:first").val());
      $('select#sale_service').val($("#select option:first").val());
      $customersdivgroup.css("display","none");
    	$physiotherapistdivgroup.css("display","flex");
     
    }else{
      $('select#sale_service').val($("#select option:first").val());
    	$physiotherapistdivgroup.css("display","none");
    	$customersdivgroup.css("display","flex");
  	  $physiotherapistid.val("");
    }
  });

  $("#sale_type_item_sale").change(function(){
    if ($("#sale_type_item_sale").val() == 0){
      $("#pacote").css({ "display": "block" });
      $("#produto").css({ "display": "none" });
      $('select#sale_product_or_services_sales_id').val($("#select option:first").val());
    }else if($("#sale_type_item_sale").val() == 1){
      $("#produto").css({ "display": "block" });
      $("#pacote").css({ "display": "none" });
      $('select#sale_packet_procedure_id').val($("#select option:first").val());
    }
  });

	$paymentform.change(function(){
    if ($('#sale_type_payment').val()=="2" || $('#sale_type_payment').val()=="4" || $('#sale_type_payment').val()=="5"){
    	$('#sale_credit_card_brand').css("display","flex");
      $('select#sale_service').val($("#select option:first").val());
    	$installments_div_row.css("display","flex");
      $installments_div_row.val($("#select option:first").val());
      
    }else if($('#sale_type_payment').val()=="1"){
			$brands.css("display","flex");
      $('select#sale_service').val($("#select option:first").val());
			$installments_div_row.css("display","none");
      $installments_div_row.val($("#select option:first").val());
      
    }else if($paymentform.val()=="0" || $paymentform.val()=="3" ){
    	$brands.css("display","none");
    	$installments_div_row.css("display","none");
 	    $installments_div_row.val($("#select option:first").val());
    }
  });

  $("#receivement_receivement_type").change(function(){
    if ( $("#receivement_receivement_type").val() == 0 ){
      $("#packet_procedure").css({ "display": "block" });
      $("#customer_fields_group").css({ "display": "none" });
      
    }else{
      $("#packet_procedure").css({ "display": "none" });
      $("#customer_fields_group").css({ "display": "block" });
    }
  });

  $('#sale_product_or_services_sales_id').change(function(){
    value_sale($('#sale_product_or_services_sales_id').val());
  });

  $('#sale_packet_procedure_id').change(function(){
    value_sale_packet($('#sale_packet_procedure_id').val());
  });

  function value_sale(id_product){
    $.ajax({
      type: 'GET',
      url: 'render_price',
      data: {id_product: $('#sale_product_or_services_sales_id').val()},
      success: function(msg){
        $("#sale_field").html(msg);
      }
    });
  }

  function value_sale_packet(id_product){
    $.ajax({
      type: 'GET',
      url: 'render_price',
      data: {id_packet: $('#sale_packet_procedure_id').val()},
      success: function(msg){
        $("#sale_field").html(msg);
      }
    });
  }
})

$(document).ready(function(){

  $type            									= $('#payment_payment_type');
  $physiotherapist_column           = $('#physiotherapists_selection');
  $physiotherapist            			= $('#payment_physiotherapist_id');
  $paymentform											= $('#payment_payment_method');
  $installments_div_row							= $('#payement_installments_row_div');
  $brands														= $('#credit_card_brand_id_div');


	$type.change(function(){
    if ($type.val()=="14"){
    	$physiotherapist_column.css("display","block");
    }else{
    	$physiotherapist_column.css("display","none");
  	
    }
  });

	$paymentform.change(function(){
    if ($paymentform.val()=="2" || $paymentform.val()=="4" || $paymentform.val()=="5"){
    	$brands.css("display","block");
    	$installments_div_row.css("display","block");
    
    }else if($paymentform.val()=="1"){
			$brands.css("display","block");
			$installments_div_row.css("display","none");
    
    }else if($paymentform.val()=="0" || $paymentform.val()=="3"  ){
    	$brands.css("display","none");
    	$installments_div_row.css("display","none");
 	
    }
  });

})
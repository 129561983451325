import { Calendar } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/list/main.css';
import '@fullcalendar/bootstrap/main.css';
import '@fullcalendar/core/locales/pt-br';

var railsEnv = $('body').data('env-url');
var profile = $('body').data('env-profile');

var URL = railsEnv;
var API = railsEnv +"/admin";

$(document).ready(function(){
  if ( window.location.href.split('/').includes('admin') && !window.location.href.split('/').includes('customers') && !window.location.href.split('/').includes('physiotherapists'))  {
    getPosts();
  };
});  

function getPosts() {
  $.ajax({
        type: "POST",
        url: API + "/attendances/attendances/get_all_localities",
        dataType: "json",
        statusCode: {
          401: function(response){
          }
        },
        success: function (response) {
          if(response.success){
            renderPosts(response)
          }else{
            renderPosts(response)
          }
        }
      });
}

function renderPosts(posts) {
  $.ajax({
    type: "POST",
    url: API + "/attendances/attendances/get_all_attendances",
    dataType: "json",
    statusCode: {
      401: function(response){
      }
    },
    success: function (response) {

      if(response.success){
        renderPost(posts,response.data);
      }else{
        renderPost(posts,response.data);
      }
    }
  });
}

function renderPost(posts,data) {
  var calendarEl = document.getElementById('calendarAdmin');
  var Localities = JSON.parse(JSON.stringify(posts).split('"locality":').join('"title":'));
      //var data = JSON.parse(JSON.stringify(data).split('"speciality_id":').join('"id":').split('"start_attendance":').join('"start":').split('"end_attendance":').join('"end":').split('"teste":').join('"aaa":'))
      
      var calendar = new Calendar(calendarEl, {
        schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
        defaultView: 'resourceTimeGridDay',
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin,resourceTimeGridPlugin ],
        timeZone: 'local',
        themeSystem: 'bootstrap',
        displayEventTime: false,
        selectable: true,
        eventLimit: true,
        height: 750,
        buttonIcons:{
          prev: 'left-single-arrow',
          next: 'right-single-arrow',
          prevYear: 'left-double-arrow',
          nextYear: 'right-double-arrow'
        },
        buttonText: {
          today: "Hoje",
          month: "Mês",
          week: "Semana",
          day: "Dia",
          list: "Lista Semanal"
        },
        header: {
          left: 'prev,next today',
          center: 'title',
          right: 'resourceTimeGridDay,dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        },
        eventClick: function(info) {
          let dataStart = new Date(info.event.start);
          let dataFormatada = ((dataStart.getDate() )) + "/" + ((dataStart.getMonth() + 1)) + "/" + dataStart.getFullYear() + " Horario: " + dataStart.getHours() + ":" + dataStart.getMinutes(); 

          let dataEnd = new Date(info.event.end);
          let dataFormatadaEnd = ((dataEnd.getDate() )) + "/" + ((dataEnd.getMonth() + 1)) + "/" + dataEnd.getFullYear() + " Horario: " + dataEnd.getHours() + ":" + dataEnd.getMinutes(); 

          $('#visualizar #physiotherapist').text(info.event._def.extendedProps.physiotherapist);
          $('#visualizar #customer').text(info.event._def.extendedProps.customer);
          $('#visualizar #title').text(info.event.title);
          $('#visualizar #sala').text(info.event._def.extendedProps.sala);
          $('#visualizar #start').text(dataFormatada);
          $('#visualizar #end').text(dataFormatadaEnd);
          $('#visualizar #description').text(info.event._def.extendedProps.name_locality);
          $('#visualizar #card_color').text(info.event._def.extendedProps.card_color);
          document.querySelector('#confirmButton').innerHTML = ``
          document.querySelector('#cancelButton').innerHTML =``

          switch(info.event._def.extendedProps.card_color ) {
            case "green":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendance-ontime')
            document.querySelector('#card_header').classList.add('text-white')
            break;

            case "blue":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendance-awaiting-confirmation')
            document.querySelector('#card_header').classList.add('text-white')
            if (info.event._def.extendedProps.status != 1 )  {
              document.querySelector('#confirmButton').innerHTML = `<a id="btn-attendenca-modal" class="btn-sm btn-success px-10" href="/admin/attendances/confirmed_attendance?id=` + info.event._def.extendedProps.attendance_id + `">Confirmar</a> `
            }  
            document.querySelector('#cancelButton').innerHTML =`<a id="btn-attendenca-modal" class="btn-sm btn-danger px-10" href="/admin/attendances/attendance_update?id=` + info.event._def.extendedProps.attendance_id + `">Cancelar</a> `
            break;

            case "red":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendance-cancelled-no-advised')
            document.querySelector('#card_header').classList.add('text-white')
            break; 

            case "orangered":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendance-cancelled-advised')
            document.querySelector('#card_header').classList.add('text-white')
            break; 

            case "salmon":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendance-concluded-without-physiotherapist')
            document.querySelector('#card_header').classList.add('text-white')
            break; 

            case "orange":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendace-concluded')
            document.querySelector('#card_header').classList.add('text-white')
            break; 

            case "gray":
            document.querySelector('#card_header').className=""
            document.querySelector('#card_header').classList.add('card-header')
            document.querySelector('#card_header').classList.add('attendance-over-due-date')
            document.querySelector('#card_header').classList.add('text-white')
            document.querySelector('#cancelButton').innerHTML =`<a id="btn-attendenca-modal" class="btn-sm btn-danger px-10" href="/admin/attendances/attendance_update?id=` + info.event._def.extendedProps.attendance_id + `">Cancelar</a> `
            break; 

          } 


          $('#visualizar').modal('show');
        },
        resources:Localities,
        events: data,
      });
calendar.setOption('locale', 'pt-br');
calendar.render();
}



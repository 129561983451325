import {formater} from '../formater/formater.js';

$(document).ready(function(){

  var numFieldsSpecialities                 = document.getElementsByName("enterprise[specialities_discount][]").length 
  var $divDiscount                          = $("#discounts")
  var numFieldsDiscount                     = document.getElementsByName("enterprise[value_discount][]").length 
  var $enterprise_group_company             = $("#enterprise_group_company")
  var $enterprise_business_partner          = $("#enterprise_business_partner")
  var $enterprise_enterprise_type           = $('#enterprise_enterprise_type')

  var $enterprise_cnpj                      = $('#enterprise_cnpj')
  var $enterprise_zip                       = $('#enterprise_zip_code')
  var $enterprise_phone                     = $('#enterprise_phone_number')
  var $enterprise_mobile                    = $('#enterprise_mobile_phone_number')
  var format = new formater()


  if ($enterprise_cnpj.val() !== ""){
    $enterprise_cnpj.keyup(function(){
      $enterprise_cnpj.val(format.applyMasc($enterprise_cnpj.val(),'cnpj'))  
    });
    $enterprise_cnpj.focusout(function(){
      $enterprise_cnpj.val(format.applyMasc($enterprise_cnpj.val(),'cnpj'))  
    });
  };

  if ($enterprise_zip.val() !== ""){
    $enterprise_zip.keyup(function(){
      $enterprise_zip.val(format.applyMasc($enterprise_zip.val(),'zipcode'))  
    });
    $enterprise_zip.focusout(function(){
      $enterprise_zip.val(format.applyMasc($enterprise_zip.val(),'zipcode'))  
    });
  };

  if ($enterprise_phone.val() !== ""){
    $enterprise_phone.keyup(function(){
      $enterprise_phone.val(format.applyMasc($enterprise_phone.val(),'tel'))  
    });
    $enterprise_phone.focusout(function(){
      $enterprise_phone.val(format.applyMasc($enterprise_phone.val(),'tel'))  
    });
  };

  if ($enterprise_mobile.val() !== ""){
    $enterprise_mobile.keyup(function(){
      $enterprise_mobile.val(format.applyMasc($enterprise_mobile.val(),'tel'))  
    });
    $enterprise_mobile.focusout(function(){
      $enterprise_mobile.val(format.applyMasc($enterprise_mobile.val(),'tel'))  
    });
  };


  $enterprise_group_company.change(function(){
    if($(this).prop('checked')) {
      $enterprise_business_partner.prop('checked', false )
      $(`#enterprise_enterprise_type option[value='']`).prop('selected', true);
    } 
  });

  $enterprise_business_partner.change(function(){
    if($(this).prop('checked')) {
      $enterprise_group_company.prop('checked', false )
      $(`#enterprise_enterprise_type option[value='Parceira']`).prop('selected', true);
    }  
  });    

  var build_discount_fields ="";

  build_discount_fields += '<div class="row form-group">' 
  build_discount_fields += '<div class="col-sm-6" >'
  build_discount_fields += '<div class="form-group">'
  build_discount_fields += '<input class="form-control" type="text" name="enterprise[value_discount][]" id="enterprise_">'
  build_discount_fields += '</div>'
  build_discount_fields += '</div>'
  build_discount_fields += '<div class="col-sm-6">  '
  build_discount_fields += '<div class="form-group">'
  build_discount_fields += '<input class="form-control" type="text" name="enterprise[percent_discount][]" id="enterprise_">'
  build_discount_fields += '</div>'
  build_discount_fields += '</div>'


  if (numFieldsDiscount < numFieldsSpecialities){
    for(var i=1; i<numFieldsSpecialities-numFieldsDiscount; i++){
      $divDiscount.find("").last().clone().appendTo($divDiscount.append(build_discount_fields))
    };
  };



});



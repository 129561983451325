import {formater} from '../formater/formater.js';

$(document).ready(function(){
	var $cpf_field_search 			= $('#query_cpf');
	var $email_login       			= $('#customer_email');
	var $email            			= $('#customer_profile_attributes_alternative_email');
	var $fone 									=	$('#customer_profile_attributes_phone_number');
	var $mobile									=	$('#customer_profile_attributes_mobile_phone_number');
	var $cpf 										= $('#customer_profile_attributes_cpf');
	var $zip_field 							= $('#customer_profile_attributes_zip_code');
	var format = new formater();


	if($email.lenght !== ""){
		$email.focusout(function(e) {
			$email_login.val($email.val())
		});
	};

	if ($fone.val() !== ""){
		$fone.keyup(function(){
			$fone.val(format.applyMasc($fone.val(),'tel'))	
		});
		$fone.focusout(function(){
			$fone.val(format.applyMasc($fone.val(),'tel'))	
		});
	};

	if ($mobile.val() !== ""){
		$mobile.keyup(function(){
			$mobile.val(format.applyMasc($mobile.val(),'tel'))	
		});
		$mobile.focusout(function(){
			$mobile.val(format.applyMasc($mobile.val(),'tel'))	
		});		
	};

	if ($cpf.val() !== ""){
		$cpf.keyup(function(){
			$cpf.val(format.applyMasc($cpf.val(),'cpf'))	
		});
		$cpf.focusout(function(){
			$cpf.val(format.applyMasc($cpf.val(),'cpf'))	
		});				
	};

	if ($cpf_field_search.val() !== ""){
		$cpf_field_search.keyup(function(){
			$cpf_field_search.val(format.applyMasc($cpf_field_search.val(),'cpf'))	
		});
		$cpf_field_search.focusout(function(){
			$cpf_field_search.val(format.applyMasc($cpf_field_search.val(),'cpf'))	
		});				
	};

	if ($zip_field.val() !== ""){
		$zip_field.keyup(function(){
			$zip_field.val(format.applyMasc($zip_field.val(),'zipcode'))	
		});
		$zip_field.focusout(function(){
			$zip_field.val(format.applyMasc($zip_field.val(),'zipcode'))	
		});				
	};

	$('#health_to_customer_health_insurance_id').change(function(){
		var valor = $('#health_to_customer_health_insurance_id').val();
		$.ajax({
			type: 'GET',
			url: 'health_insurance_plans',
			beforeSend: function(){
				$("#data").html('<div class="alert alert-info" role="alert">Carregando planos e produtos</div>');
			},
			data: {health_insurance_id: valor},
			success: function(msg){
				console.log(msg);
				$("#product_plan").html(msg);
			}
		})
	});

});
export class formater{

	constructor(){
	};

	applyMasc(value, masc){
		switch(masc) {
			case 'tel':
			return this.foneMasc(value)
			break;
			case 'cpf':
			return this.cpfMask(value)
			break;
			case 'cnpj':
			return this.cnpjMask(value)
			break;
			case 'zipcode':
			return this.zipMask(value)
			break;
			case 'number':
			return this.numberMask(value)
			break;
			default:
    	return value
  	} 
	};


	foneMasc(tel) {
		tel=tel.replace(/\D/g,"")
		tel=tel.replace(/^(\d)/,"($1")
		tel=tel.replace(/(.{3})(\d)/,"$1)$2")
		if(tel.length == 9) {
			tel=tel.replace(/(.{1})$/,"-$1")
		} else if (tel.length == 10) {
			tel=tel.replace(/(.{2})$/,"-$1")
		} else if (tel.length == 11) {
			tel=tel.replace(/(.{3})$/,"-$1")
		} else if (tel.length == 12) {
			tel=tel.replace(/(.{4})$/,"-$1")
		} else if (tel.length > 12) {
			tel=tel.replace(/(.{4})$/,"-$1")
		}
		return tel;
	};

	cnpjMask(cnpj){
		cnpj=cnpj.replace(/\D/g,"")
		cnpj=cnpj.replace(/^(\d{2})(\d)/,"$1.$2")
		cnpj=cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
		cnpj=cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")
		cnpj=cnpj.replace(/(\d{4})(\d)/,"$1-$2")
		return cnpj
	};

	cpfMask(cpf){
		cpf=cpf.replace(/\D/g,"")
		cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
		cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
		cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
		return cpf
	};

	zipMask(cep){
		cep=cep.replace(/\D/g,"")
		cep=cep.replace(/^(\d{2})(\d)/,"$1.$2")
		cep=cep.replace(/\.(\d{3})(\d)/,".$1-$2")
		return cep
	};

	numberMask(num){
		num=num.replace(/\D/g,"")
		return num
	};

};
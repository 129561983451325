
$(document).ready(function(){
  if (document.getElementById('customer_profile_attributes_cpf')) {
    var userInput = document.getElementById('customer_profile_attributes_cpf').value;
    
    var QRCode = require('qrcode')
    var canvas = document.getElementById('canvas')
    QRCode.toCanvas(canvas, String(userInput.value), {
      width: 120,
      height: 120,
      colorDark: "black",
      colorLight: "white",
      errorCorrectionLevel: 'H'
    }, function (error) {
      if (error) console.error(error)
        console.log('success!');
    })
  }
  /*var qrcode = new QRCode("qrcode", {
    text: userInput,
    width: 120,
    height: 120,
    colorDark: "black",
    colorLight: "white",
    correctLevel : QRCode.CorrectLevel.H
  });*/
});
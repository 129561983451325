import {formater} from '../formater/formater.js';

$(document).ready(function(){

	var $physio_email_login       			= $('#physiotherapist_email');
	var $physio_email 	           			= $('#physiotherapist_profile_attributes_alternative_email');
	var $physio_fone 										=	$('#physiotherapist_profile_attributes_phone_number');
	var $physio_mobile									=	$('#physiotherapist_profile_attributes_mobile_phone_number');
	var $physio_cpf 										= $('#physiotherapist_profile_attributes_cpf');
	var $physio_cep 										= $('#physiotherapist_profile_attributes_zip_code');
	var $physio_contact_phone						= $('input[name^="physiotherapist[professional_attributes][emergeny_contact_phone]"]')
	var format = new formater()


	if($physio_email.lenght !== ""){
		$physio_email.focusout(function(e) {
			$physio_email_login.val($physio_email.val())
		});
	};

	if ($physio_fone.val() !== ""){
		$physio_fone.keyup(function(){
			$physio_fone.val(format.applyMasc($physio_fone.val(),'tel'))	
		});
		$physio_fone.focusout(function(){
			$physio_fone.val(format.applyMasc($physio_fone.val(),'tel'))	
		});
	};

	if ($physio_mobile.val() !== ""){
		$physio_mobile.keyup(function(){
			$physio_mobile.val(format.applyMasc($physio_mobile.val(),'tel'))	
		});
		$physio_mobile.focusout(function(){
			$physio_mobile.val(format.applyMasc($physio_mobile.val(),'tel'))	
		});		
	};


	$physio_contact_phone.each(function() {
    if ($(this).val() !== ""){
		$(this).keyup(function(){
			$(this).val(format.applyMasc($(this).val(),'tel'))	
		});
		$(this).focusout(function(){
			$(this).val(format.applyMasc($(this).val(),'tel'))	
		});		
	};
	});
	

	if ($physio_cpf.val() !== ""){
		$physio_cpf.keyup(function(){
			$physio_cpf.val(format.applyMasc($physio_cpf.val(),'cpf'))	
		});
		$physio_cpf.focusout(function(){
			$physio_cpf.val(format.applyMasc($physio_cpf.val(),'cpf'))	
		});				
	};

	if ($physio_cep.val() !== ""){
		$physio_cep.keyup(function(){
			$physio_cep.val(format.applyMasc($physio_cep.val(),'zipcode'))	
		});
		$physio_cep.focusout(function(){
			$physio_cep.val(format.applyMasc($physio_cep.val(),'zipcode'))	
		});				
	};



});
$(document).ready(function(){
  $cepEnterprise            			= $('#enterprise_zip_code')
  $cepProfilePhysitherapist       = $('#physiotherapist_profile_attributes_zip_code')
  $cepProfileCustomer				 			= $('#customer_profile_attributes_zip_code')
   

  if($cepEnterprise.lenght !== 0){
    $cepEnterprise.focusout(function(e) {
      getAddressInfos(e, "enterprise")
    });
  }

  if($cepProfilePhysitherapist.lenght !== 0){
    $cepProfilePhysitherapist.focusout(function(e) {
      getAddressInfos(e, "physiotherapist" )
    });
  }

  if($cepProfileCustomer.lenght !== 0){
    $cepProfileCustomer.focusout(function(e) {
      getAddressInfos(e, "customer" )
    });
  }


})  

function getAddressInfos(e, cepType) {
  $.ajax({
    url: '/shared_function/address-infos?cep=' + e.target.value,
    type: 'POST',
  })
  .done(function(response) {
    if (cepType === "enterprise") { 
      insertResponseOnEnterpriseInputs(response)
    }else if (cepType === "physiotherapist"){
      insertResponseOnPhysiotherapistInputs(response)
    }else{
      insertResponseOnCustomerInputs(response)
    }

  })
  .fail(function() {
    alert('CEP não encontrado, tente novamente!')
  })
}

function insertResponseOnEnterpriseInputs(response) {
  $('#enterprise_address').val(response.address);
  $('#enterprise_state').val(response.state);
  $('#enterprise_city').val(response.city);
  $('#enterprise_neighbohood').val(response.neighborhood);
}

function insertResponseOnPhysiotherapistInputs(response) {
	$('#physiotherapist_profile_attributes_address').val(response.address);
  $('#physiotherapist_profile_attributes_state').val(response.state);
  $('#physiotherapist_profile_attributes_city').val(response.city);
  $('#physiotherapist_profile_attributes_neighbohood').val(response.neighborhood);
}

function insertResponseOnCustomerInputs(response) {
  $('#customer_profile_attributes_address').val(response.address);
  $('#customer_profile_attributes_state').val(response.state);
  $('#customer_profile_attributes_city').val(response.city);
  $('#customer_profile_attributes_neighbohood').val(response.neighborhood);
}